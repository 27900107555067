class ScrollingPage {
    constructor(parent, data) {
        this.parent = parent
        this.data = data
    }
    run() {
        this.el = document.querySelector('[data-header]')
        if (!this.el) {
            return
        }
        window.addEventListener('scroll', () => this.onScroll())
        this.onScroll()
    }
    onScroll() {
        if (window.scrollY > 0) {
            this.el.classList.add("menu--is-fixed")
        } else {
            this.el.classList.remove("menu--is-fixed")
        }
    }
}

export default ScrollingPage