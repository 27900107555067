class Menu {
    constructor(parent, data) {
        this.parent = parent
        this.data = data
        this.classOpen = 'menu--is-open'
    }
    run() {
        document.querySelectorAll('[data-menu]').forEach((el) => this.init(el))
    }
    init(elMenu) {
        const elID = elMenu.dataset.menu;
        document.querySelectorAll('[data-menu-toggle="' + elID + '"]').forEach((el) => this.initAction(el, elMenu, 'toggle'))
        document.querySelectorAll('[data-menu-open="' + elID + '"]').forEach((el) => this.initAction(el, elMenu, 'open'))
        document.querySelectorAll('[data-menu-close="' + elID + '"]').forEach((el) => this.initAction(el, elMenu, 'close'))
    }
    initAction(elAction, elMenu, actionType) {
        elAction.addEventListener('click', () => {
            if ('open' === actionType) {
                elMenu.classList.add(this.classOpen)
            } else if ('close' === actionType) {
                elMenu.classList.remove(this.classOpen)
            } else {
                elMenu.classList.toggle(this.classOpen)
            }
        })
    }
}

export default Menu