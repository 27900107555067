// import ComponentA from './Components/ComponentA'
import Menu from './Components/Menu'
import ScrollingPage from './Components/ScrollingPage'
document.addEventListener('DOMContentLoaded', () => {

    let root = {}
    root.menu = new Menu(root, [])
    root.menu.run()
    var data = []
    // (new ComponentA()).run()

    root.scrollingPage = new ScrollingPage(root, [])
    root.scrollingPage.run()

}, false)